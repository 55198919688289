import type { FirebaseOptions } from 'firebase/app';

export type PlantigaConfig = {
  site: string;
  serviceWorker: boolean;
  firebaseConfig: FirebaseOptions;
  domain: string;
  apiUrl: string;
  stripeKey: string;
  stripePortalURL: string;
  stripePlans: Record<string, Record<string, string>>;
  sentryDSN: string;
  dynamicLinkBase: string;
  featureFlags: Record<string, boolean>;
  dockTarget: 'DEV' | 'PROD';
};

const configUrl = `/assets/site.mjs?v=${process.env.BUILD_VERSION}`;
const config: PlantigaConfig = (await import(/* @vite-ignore */ configUrl)).default;
console.log(`load site Plantiga ${config.site}`); // eslint-disable-line no-console

export default config;
