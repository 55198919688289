// ignore unused exports
import React from 'react';
import { Outlet } from 'react-router';

import { SnackbarProvider } from 'notistack';
import { makeStyles } from 'tss-react/mui';

import { CssBaseline } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { SwContextProvider } from 'plantiga-common/ServiceWorker/SwContext';
import ErrorBoundary from 'plantiga-component/Error/Boundary';
import ErrorElement from 'plantiga-component/Error/ErrorElement';
import Splash from 'plantiga-component/Firestore/Auth/Splash';
import FirebaseAnalytics from 'plantiga-component/Instrumentation/FirebaseAnalytics';
import { usePlantigaTheme } from 'plantiga-component/theme';

const useStyles = makeStyles()((theme) => ({
  appBackground: {
    backgroundColor: theme.palette.background.default,
  },
}));

function AppWithTheme() {
  const { classes } = useStyles();
  return (
    <>
      <CssBaseline />
      <div className={classes.appBackground}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SnackbarProvider maxSnack={3}>
            <>
              <ErrorBoundary topLevel>
                <Outlet />
              </ErrorBoundary>
              <FirebaseAnalytics />
            </>
          </SnackbarProvider>
        </LocalizationProvider>
      </div>
    </>
  );
}

function App() {
  const theme = usePlantigaTheme();

  return (
    <>
      <Splash />
      <SwContextProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <AppWithTheme />
          </ThemeProvider>
        </StyledEngineProvider>
      </SwContextProvider>
    </>
  );
}

export const errorElement = <ErrorElement />;

export default App;
