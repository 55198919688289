import { useLocalStorage } from 'usehooks-ts';

export default function useThemeMode() {
  const [version, setVersion] = useLocalStorage<'v1' | 'v2' | 'v1ColorBlind'>(
    'plantigaThemeVersion',
    'v1', // TODO: set this to v2 when ready to release colors
  );

  return { version, setVersion } as const;
}
