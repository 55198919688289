import React from 'react';
import { RouterProvider, type RouteObject } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';

import makeDebug from 'debug';
import { createBrowserHistory } from 'history';
import { createRoot } from 'react-dom/client';

import redirectMap, { redirectRoute } from './framework/redirect-map';
import App, { errorElement } from './root';
import routeObjects from './routes.gen';

const debug = makeDebug('plantiga:shell');

const history = createBrowserHistory();

const element = document.getElementById('root');
if (element == null) {
  throw new Error("Could not get element with id='root'");
}

const routes: RouteObject[] = [
  {
    element: <App />,
    children: routeObjects,
    errorElement,
  },
];
Object.entries(redirectMap).forEach(([from, to]) => {
  routes.push(redirectRoute(from, to));
});

debug('routes: ', routes);
const component = (
  <RouterProvider
    router={createBrowserRouter(routes, {
      future: {
        v7_relativeSplatPath: true,
        v7_fetcherPersist: true,
        v7_normalizeFormMethod: true,
        v7_partialHydration: true,
        v7_skipActionErrorRevalidation: true,
      },
    })}
  />
);

debug('Start Render');
createRoot(element).render(component);

const loadTime = performance.now();

// asynchronously initialize instrumentation
// using Top-Level Await (TLA) causes a deadlock here.
// See https://github.com/rollup/rollup/issues/4708
import('./util/instrumentation').then(({ initialize }) => {
  initialize(history, loadTime);
});
