import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Button, CardActions, CardContent, CardHeader, CardMedia, Typography } from '@mui/material';

import brokenURL from 'plantiga-assets/broken_icon_shadow512.png';

import useHardRefresh from './useHardRefresh';

const useStyles = makeStyles()((theme) => ({
  container: {
    maxWidth: theme.breakpoints.values.sm,
  },
  media: {
    maxWidth: theme.breakpoints.values.sm,
    maxHeight: '33vh',
    objectFit: 'scale-down',
  },
}));

type Props = {
  img?: string;
  title?: React.ReactNode;
  message?: React.ReactNode;
};

export default function ErrorCard({ img, title, message }: Props) {
  const { classes } = useStyles();

  const hardRefresh = useHardRefresh();

  return (
    <div className={classes.container}>
      <CardMedia
        component="img"
        alt="Broken Glass"
        className={classes.media}
        image={img || brokenURL}
        title="Broken Glass"
      />

      <CardHeader
        title={<Typography variant="h4">{title || 'An unexpected error occured.'}</Typography>}
      />
      <CardContent>
        {message || (
          <Typography variant="body1" gutterBottom>
            The page is currently not available. We are working on the problem and appreciate your
            patience.
          </Typography>
        )}
      </CardContent>
      <CardActions>
        <Button onClick={hardRefresh} color="primary" variant="outlined">
          Retry
        </Button>
      </CardActions>
    </div>
  );
}
