import React from 'react';
import { generatePath, redirect, type RouteObject } from 'react-router';

export type RouteModule = {
  default: NonNullable<RouteObject['Component']>;
  clientLoader?: RouteObject['loader'];
};

export function redirectRoute(from: string, to: string | React.ComponentType): RouteObject {
  if (typeof to === 'string')
    return {
      path: from,
      loader: ({ params }) => redirect(generatePath(to, params)),
    };

  return {
    path: from,
    Component: to,
  };
}

// prettier-ignore
export default {
  '/org/:teamId/research': '/org/:teamId/export',

  '/org/:teamId/profile': '/org/:teamId/profile/preferences',
  '/org/:teamId/profile/coaches': '/org/:teamId/profile/users',
  '/org/:teamId/profile/cloud-adapters': '/org/:teamId/devices/docks',

  '/org/:teamId/pods': '/org/:teamId/devices/pods',

  '/org/:teamId/people/:athleteId/activity/:activityId/raw': '/org/:teamId/people/:athleteId/activity/:activityId/explore',
  '/org/:teamId/people/:athleteId/feed/:day/activityType/:type': '/org/:teamId/people/:athleteId/daily/:day/activityType/:type',
  '/org/:teamId/people/:athleteId/daily/:day/activity/:activityId/summaries': '/org/:teamId/people/:athleteId/daily/:day/activity/:activityId/stats-for-nerds',

  '/org/:teamId/stopwatch/:activityTypeId?': '/org/:teamId/record/:activityTypeId?',
  '/org/:teamId/stopwatch/:activityTypeId/running/:swid': '/org/:teamId/record/:activityTypeId/recording/:swid',
  '/org/:teamId/stopwatch/:activityTypeId/finalized/:swid': '/org/:teamId/record/:activityTypeId/finalize/:swid',
};
