import { initializeApp, type FirebaseApp } from 'firebase/app';
import { type UserCredential } from 'firebase/auth';

import makeDebug from 'debug';

import config from './config';

const debug = makeDebug('plantiga:main');

export type Plantiga = {
  firebase: FirebaseApp;
  signInCi: (token: string) => Promise<UserCredential>;
  history: any;
  version: string | null | undefined;
};

const loadTime = performance.now();
debug(`plantiga.io (loadTime:${loadTime.toFixed(0)}ms)`, process.env.BUILD_VERSION);

export const app = initializeApp(config.firebaseConfig);

// @ts-expect-error
Object.assign(window.plantiga, {
  firebase: app,
  version: process.env.BUILD_VERSION,
});
