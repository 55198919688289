import React from 'react';

export default function useHardRefresh(): () => Promise<void> {
  return React.useCallback(async () => {
    // Absolutely wreck old service worker caches.
    await Promise.all([
      caches.delete(`workbox-precache-v2-${window.location.origin}/`),
      caches.delete(`workbox-runtime-${window.location.origin}/`),
    ]);
    // Cache bust and force new page load
    const usp = new URLSearchParams(window.location.search);
    usp.set('new-version', Date.now().toString());
    window.location.search = usp.toString();
  }, []);
}
