import { logEvent } from 'firebase/analytics';
import React from 'react';
import { useLocation } from 'react-router';

import { useAnalytics } from 'plantiga-firebase/analytics';
import { getURLKey } from 'plantiga-util/urls';

export default function FirebaseAnalytics(): null {
  const analytics = useAnalytics();
  const location = useLocation();

  React.useEffect(() => {
    const URLKey = getURLKey(location.pathname);
    logEvent(analytics, 'screen_view', { firebase_screen: URLKey, firebase_screen_class: URLKey });
  }, [location, analytics]);
  return null;
}
