import React from 'react';

import { withStyles } from 'tss-react/mui';

import * as Sentry from '@sentry/react';

import { isFirestoreBug, isChunkLoadError } from '../../util/errors';

import ErrorCard from './ErrorCard';
import NewVersionCard from './NewVersionCard';
import ReloadRequiredCard from './ReloadRequiredCard';

const styles = () =>
  ({
    errorRoot: {
      flex: 1,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  } as const);

type Props = {
  readonly children: any;
  readonly classes?: Record<'errorRoot', string>;
  readonly topLevel?: boolean;
};

class ErrorBoundary extends React.Component<
  Props,
  {
    error: unknown;
  }
> {
  state: {
    error: unknown;
  } = { error: null };

  static defaultProps: {
    topLevel: boolean;
  } = {
    topLevel: false,
  };

  componentDidMount() {
    const { topLevel } = this.props;
    if (topLevel) {
      window.addEventListener('error', this.onError);
      window.addEventListener('unhandledrejection', this.onUnhandled);
    }
  }

  componentWillUnmount() {
    const { topLevel } = this.props;
    if (topLevel) {
      window.removeEventListener('error', this.onError);
      window.removeEventListener('unhandledrejection', this.onUnhandled);
    }
  }

  onUnhandled: (arg1: { reason: Error }) => void = ({ reason: error }: { reason: Error }) => {
    this.onError({ error });
  };

  onError: (arg1: { error: Error }) => void = ({ error }: { error: Error }) => {
    // For now, only deal with Firestore internal assertions
    if (isFirestoreBug(error)) {
      this.setState({ error });
    }
  };

  static getDerivedStateFromError(error: Error): {
    error: Error;
  } {
    // Update state so the next render will show the fallback UI.
    console.error(error);
    Sentry.addBreadcrumb({
      message: 'Stop propagation in RouterComponent',
    });

    // Do not ChunkLoadErrors to sentry
    const shouldSendToSentry = !isChunkLoadError(error);

    if (shouldSendToSentry) {
      Sentry.captureException(error);
    }

    return { error };
  }

  render(): React.ReactNode {
    const { error } = this.state;
    const { children, classes } = this.props;

    if (error) {
      let errorNode = <ErrorCard />;
      if (isChunkLoadError(error)) {
        errorNode = <NewVersionCard />;
      }
      if (isFirestoreBug(error)) {
        errorNode = <ReloadRequiredCard />;
      }

      return <div className={classes?.errorRoot}>{errorNode}</div>;
    }

    return children;
  }
}

const ErrorBoundaryWithStyles = withStyles(ErrorBoundary, styles);

export default React.memo((props: Omit<Props, 'classes'>) => (
  <ErrorBoundaryWithStyles {...props} />
));
