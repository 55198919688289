import { matchPath } from 'react-router';

import { findKey, memoize } from 'lodash-es';

import urls from './map';

export const external = {
  help: {
    // Legal
    tos: 'https://plantiga.com/legal/',
    privacyPolicy: 'https://plantiga.com/legal/#plantiga-privacy',

    guides: 'https://plantiga.notion.site/Plantiga-Knowledge-Base-a32a6d79e2914efc810b48b994486824',
    requestDemo: 'https://plantiga.com/demo',
  },

  youtube: {
    height_single_leg_jump:
      'https://www.youtube.com/embed/OnccBmcJs7I?autoplay=0&controls=0&mute=1',
    single_leg_jump_distance:
      'https://www.youtube.com/embed/DNkhs3UXCu4?autoplay=0&controls=0&mute=1',
    single_leg_jump_rsi: 'https://www.youtube.com/embed/7ccBFLgsC3U?autoplay=0&controls=0&mute=1',
    consecutive_cmj: 'https://www.youtube.com/embed/-_9EPlB7lzU?autoplay=0&controls=0&mute=1',
    Cyclic_jump: 'https://www.youtube.com/embed/jEtrceioezE?autoplay=0&controls=0&mute=1',
    run: 'https://www.youtube.com/embed/e7j8oH63WcY?autoplay=0&controls=0&mute=1',
    walk: 'https://www.youtube.com/embed/O-yJFXU6IXY?autoplay=0&controls=0&mute=1',
  },

  typeform: {
    shippingInfo: 'https://plantigasurvey.typeform.com/to/mmdARI',
    typeformSurvey: 'https://plantigasurvey.typeform.com/to/:surveyId',
  },

  hubspot: {
    meetingsEmbed: 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js',
  },

  downloads: 'https://storage.googleapis.com/plantiga-prod-downloads/apps/latest',
} as const;

/**
 * @deprecated use $routeId instead
 */
export type Urls = typeof urls;

/**
 * @deprecated use $routeId instead
 */
export const getUrls = memoize(() => urls);

/**
 * @deprecated use $routeId instead
 */
export type urlKey = keyof Urls;

export type UrlParams = {
  teamId?: string;
  athleteId?: string;
  activityId?: string;
  type?: string; // activityType
  day?: string;
  page?: string;
  templateId?: string;
};

/**
Build a url from a route name and params

@param name {string} must be in the routes
@param params {Object} populate route template with these variables

example::

    urls.build('editAthlete', { athleteId })

example::

    <Route path={urls.editAthlete} />

@deprecated use $routeId('/') instead of routeName
*/
export const getURLKey = (path: string): any | string => {
  const URLKey = findKey(getUrls(), (url) => matchPath({ path: url, end: true }, path));
  return URLKey == null ? '404' : URLKey;
};
