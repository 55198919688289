/// <reference types="vite-plugin-pwa/client" />

import React from 'react';

import { useRegisterSW } from 'virtual:pwa-register/react';

export const SwContext = React.createContext<ReturnType<typeof useRegisterSW> | undefined>(
  undefined,
);

type Props = {
  children: React.ReactChild;
};

const intervalMS = 60 * 60 * 1000;

function setupPeriodicUpdates(swUrl: string, r: ServiceWorkerRegistration) {
  setInterval(async () => {
    if (!(!r.installing && navigator)) return;

    if ('connection' in navigator && !navigator.onLine) return;

    const resp = await fetch(swUrl, {
      headers: {
        cache: 'no-store',
        'cache-control': 'no-cache',
      },
    });

    if (resp?.status === 200) await r.update();
  }, intervalMS);
}

export function SwContextProvider({ children }: Props) {
  const sw = useRegisterSW({
    onRegisteredSW(swScriptUrl, registration) {
      console.log(`SW Registered: ${registration}`);
      if (registration) {
        setupPeriodicUpdates(swScriptUrl, registration);
      }
    },
    onRegisterError(error) {
      console.log('SW registration error', error);
    },
  });

  return <SwContext.Provider value={sw}>{children}</SwContext.Provider>;
}
