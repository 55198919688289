import type { Event, EventHint } from '@sentry/react';

// https://sentry.io/organizations/plantiga/issues/1249675286/events/ba8c5f449bbe4c6f9e9ad07a45d1f03e/?environment=beta&statsPeriod=14d
// This is a firestore internal error that can be triggered by a number of different things:
// https://github.com/firebase/firebase-js-sdk/issues/1642
// An iOS IndexedDB bug: https://github.com/firebase/firebase-js-sdk/issues/1670
// Chrome being suspended: https://github.com/firebase/firebase-js-sdk/issues/939
//                         https://bugs.chromium.org/p/chromium/issues/detail?id=855624
export function isFirestoreBug(error: unknown): boolean {
  return error instanceof Error && error.message.indexOf('INTERNAL ASSERTION') !== -1;
}

export function isChunkLoadError(error: unknown): boolean {
  return error instanceof Error && error.name === 'ChunkLoadError';
}

export function filterSentryErrors(event: Event, hint: EventHint): Event | null {
  const error = hint.originalException;
  if (isFirestoreBug(error)) {
    return null;
  }
  if (
    error instanceof Error &&
    error.message.match(/Failed to execute 'transaction' on 'IDBDatabase'/)
  ) {
    return null;
  }
  return event;
}
