import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Button, Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material';

import useHardRefresh from './useHardRefresh';

const useStyles = makeStyles()((theme) => ({
  container: {
    maxWidth: theme.breakpoints.values.sm,
  },
}));

export default function ErrorCard() {
  const { classes } = useStyles();

  const hardRefresh = useHardRefresh();

  return (
    <Card className={classes.container} elevation={0}>
      <CardHeader
        title={<Typography variant="h4">A new version of plantiga.io has been released</Typography>}
      />
      <CardContent />
      <CardActions>
        <Button onClick={hardRefresh} color="primary" variant="contained">
          Load new version
        </Button>
      </CardActions>
    </Card>
  );
}
