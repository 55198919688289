import {
  browserLocalPersistence,
  browserPopupRedirectResolver,
  browserSessionPersistence,
  debugErrorMap,
  indexedDBLocalPersistence,
  initializeAuth,
  inMemoryPersistence,
  setPersistence,
  signInWithCustomToken,
} from 'firebase/auth';

import { app } from './app';

const isJsdom = typeof navigator === 'object' && /jsdom/.test(navigator.userAgent);
const auth = initializeAuth(app, {
  // This adds 10kB minified/gzipped of string messages for resolving API errors into
  // user-readable messages.
  errorMap: debugErrorMap,
  // See https://github.com/firebase/firebase-js-sdk/blob/cdada6c68f9740d13dd6674bcb658e28e68253b6/packages/auth/src/platform_browser/index.ts#L44-L51
  popupRedirectResolver: isJsdom ? undefined : browserPopupRedirectResolver,
  persistence: isJsdom
    ? inMemoryPersistence
    : [indexedDBLocalPersistence, browserLocalPersistence, browserSessionPersistence],
});

// @ts-expect-error
Object.assign(window.plantiga, {
  async signInCi(token: string) {
    await setPersistence(auth, browserSessionPersistence);
    return signInWithCustomToken(auth, token);
  },
});

export const getToken = async () => {
  await auth.authStateReady();
  if (auth.currentUser === null) return '-';
  return auth.currentUser.getIdToken();
};

export default auth;
