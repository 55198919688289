import { type User } from 'firebase/auth';
import React, { useCallback, useEffect, useRef } from 'react';

import makeDebug from 'debug';

import auth from '~/auth';

const debug = makeDebug('plantiga:splash');

export default function Splash() {
  const splashElement = useRef<HTMLDivElement | null>(null);
  const previousUser = useRef<User | null>(null);

  const hideSplash = () => {
    debug('Hiding splash');
    const element = document.getElementById('splash');
    if (element) {
      element.classList.add('hidden');
    }
  };

  const showSplash = () => {
    debug('Revealing splash');
    const element = document.getElementById('splash');
    if (element) {
      element.classList.remove('hidden');
    }
  };

  const changed = useCallback((user: User | null) => {
    if (previousUser.current != null && user == null) {
      showSplash();
    } else {
      hideSplash();
    }
    previousUser.current = user;
  }, []);

  // setup the splash, should only run on mount
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(changed, () => null);
    return unsubscribe;
  }, [changed]);

  return <div ref={splashElement} />;
}
