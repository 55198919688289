import React from 'react';
import { useRouteError } from 'react-router';

import { useStyles } from 'tss-react/mui';

import { isChunkLoadError, isFirestoreBug } from 'plantiga-util/errors';

import ErrorCard from './ErrorCard';
import NewVersionCard from './NewVersionCard';
import ReloadRequiredCard from './ReloadRequiredCard';

export default function ErrorElement() {
  const error = useRouteError();
  const { css } = useStyles();

  let errorNode = <ErrorCard />;
  if (isChunkLoadError(error)) {
    errorNode = <NewVersionCard />;
  }
  if (isFirestoreBug(error)) {
    errorNode = <ReloadRequiredCard />;
  }

  return (
    <div
      className={css({
        flex: 1,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      })}
    >
      {errorNode}
    </div>
  );
}
