import { schemePurples as activityScheme } from 'd3';

type Color = NonNullable<React.CSSProperties['color']>;

type ColorScheme<key extends string = string> = Record<key, Color>;

type ColorSchemeVersions<K extends string = string, Scheme = ColorScheme<K>> = {
  v1: Scheme;
  v2?: Scheme;
  v1ColorBlind?: Scheme;
};

type Chirality = 'left' | 'right' | 'achiral' | 'none';
export type Quality = 'none' | 'extreme' | 'veryHigh' | 'high' | 'medium' | 'low';

const LOCATION_COLOR_SCHEME: ColorSchemeVersions<Chirality> = {
  v2: {
    left: '#4BB3FD',
    right: '#084B84',
    achiral: '#3E6680',
    none: '#DBDBDB',
  },
  v1: {
    left: '#F3A422',
    right: '#66cc99',
    achiral: '#2a88e5',
    none: '#DBDBDB',
  },
  v1ColorBlind: {
    left: '#084B84',
    right: '#FF7A00',
    achiral: '#3E6680',
    none: '#DBDBDB',
  },
};

const ACTIVITY_COLOR_SCHEME: ColorSchemeVersions = {
  v1: {
    idle: activityScheme[5][0],
    walk: activityScheme[5][4],
    run: activityScheme[5][3],
    bilateral_jump: activityScheme[5][2],
    unilateral_jump: activityScheme[5][1],
  },
};

const QUALITY_COLOR_SCHEME: ColorSchemeVersions<Quality> = {
  v1: {
    none: '#2a88e5',
    extreme: '#880000',
    veryHigh: '#B41E19',
    high: '#e03c32',
    medium: '#ffd301',
    low: '#44cd84',
  },
  v2: {
    none: '#3E6680',
    extreme: '#880000',
    veryHigh: '#B41E19',
    high: '#e03c32',
    medium: '#ffd301',
    low: '#44cd84',
  },
};

const QUALITY_GRAY_SCALE_SCHEME: ColorSchemeVersions<Quality> = {
  v1: {
    none: '#DBDBDB',
    extreme: '#000000',
    veryHigh: '#222222',
    high: '#444444',
    medium: '#888888',
    low: '#CCCCCC',
  },
};

const GRAPHING_COLOR_SCHEME: ColorSchemeVersions = {
  v1: {
    orange: 'rgba(242,159,16,0.5)',
    green: 'rgba(3,201,145,0.5)',
    solidOrange: 'rgba(242,159,16,1)',
    lightBlue: 'rgba(187,222,251,1)',
    blue: 'rgba(42,136,229,1)',
    darkBlue: 'rgba(16,89,181,1)',
    gray: '#DBDBDB',
    darkGray: '#808080',
    red: 'rgba(243,104,34,1)',
    summaryMask: 'rgba(187,222,251,0.25)',
  },
  v1ColorBlind: {
    orange: 'rgba(242,159,16,0.5)',
    green: '#2B88E5',
    solidOrange: 'rgba(242,159,16,1)',
    lightBlue: 'rgba(187,222,251,1)',
    blue: '#606060',
    darkBlue: 'rgba(16,89,181,1)',
    gray: '#DBDBDB',
    darkGray: '#808080',
    red: 'rgba(243,104,34,1)',
    summaryMask: 'rgba(187,222,251,0.25)',
  },
};

const PLANTIGA_COLOR_SCHEME: ColorSchemeVersions<
  'green' | 'orange' | 'charcoal' | 'lightCharcoal' | 'mist' | 'white'
> = {
  v1: {
    green: '#66cc99',
    orange: '#F3A422',
    charcoal: '#424242',
    lightCharcoal: '#606060',
    mist: '#EBEBEB',
    white: '#FFFFFF',
  },
};

const extractScheme = <K extends string, Scheme extends ColorScheme>(
  schemeVersions: ColorSchemeVersions<K, Scheme>,
  version: keyof ColorSchemeVersions<K, Scheme>,
): Scheme => schemeVersions[version] ?? schemeVersions.v2 ?? schemeVersions.v1;

export const makeColorScheme = (version: 'v1ColorBlind' | 'v1' | 'v2') => ({
  ...extractScheme(PLANTIGA_COLOR_SCHEME, version),
  graphing: extractScheme(GRAPHING_COLOR_SCHEME, version),
  activity: extractScheme(ACTIVITY_COLOR_SCHEME, version),
  quality: extractScheme(QUALITY_COLOR_SCHEME, version),
  qualityGrayScale: extractScheme(QUALITY_GRAY_SCALE_SCHEME, version),
  location: extractScheme(LOCATION_COLOR_SCHEME, version),
});

export const PLANTIGA_GRAPH_SERIES_SCHEME = ['#FE595D', '#FEC93A', '#8AC927', '#01BFF4', '#B084CC'];
