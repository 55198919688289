import { getAnalytics, logEvent } from 'firebase/analytics';
import React from 'react';

export function useAnalytics() {
  return React.useMemo(() => getAnalytics(), []);
}

export function useLogEvent() {
  return React.useCallback((event: string, options?: any) => {
    logEvent(getAnalytics(), event, options);
  }, []);
}

export function useLogEventOnMount(event: string, options?: any): void {
  return React.useEffect(() => {
    logEvent(getAnalytics(), event, options);
  }, [event, options]);
}
