import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Button, Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  container: {
    maxWidth: theme.breakpoints.values.sm,
  },
}));

export default function ErrorCard() {
  const { classes } = useStyles();

  const onClick = React.useCallback(() => {
    // Cache bust and force new page load
    const usp = new URLSearchParams(window.location.search);
    usp.set('new-version', Date.now().toString());
    window.location.search = usp.toString();
  }, []);

  return (
    <Card className={classes.container} elevation={0}>
      <CardHeader title={<Typography variant="h4">A reload is required</Typography>} />
      <CardContent />
      <CardActions>
        <Button onClick={onClick} color="primary" variant="contained">
          Reload
        </Button>
      </CardActions>
    </Card>
  );
}
